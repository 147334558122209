* {
  --cyan: rgba(12, 128, 195, 1);
  --cyanBtn: rgba(24, 159, 250, 1);
  --greenModal: rgba(7, 127, 139, 1);
  --blue: rgba(6, 68, 119, 1);
  --blueLive: rgba(6, 18, 169, 1);
  --yellow: rgba(240, 144, 6, 1);
  --gray: rgba(73, 68, 68, 1);
  --grayDisabled: rgba(73, 68, 68, 0.5);
  --grayTitles: rgba(99, 99, 99, 1);
  --grayLight: rgba(228, 228, 228, 1);
  --black: rgba(0, 0, 0, 1);
  --white: rgba(255, 255, 255, 1);
  --whiteSoft: rgba(252, 253, 255, 1);
}

.BrandonGrotesqueBold {
  font-family: "Brandon Grotesque";
  font-weight: bold;
  font-style: normal;
}

.BrandonGrotesqueBlackItalic {
  font-family: "Brandon Grotesque";
  font-weight: 900;
  font-style: italic;
}

.BrandonGrotesqueBlack {
  font-family: "Brandon Grotesque";
  font-weight: 900;
  font-style: normal;
}

.BrandonGrotesqueLightItalic {
  font-family: "Brandon Grotesque";
  font-weight: 300;
  font-style: italic;
}

.BrandonGrotesqueBoldItalic {
  font-family: "Brandon Grotesque";
  font-weight: bold;
  font-style: italic;
}

.BrandonGrotesqueLight {
  font-family: "Brandon Grotesque";
  font-weight: 300;
  font-style: normal;
}

.BrandonGrotesqueRegular {
  font-family: "Brandon Grotesque";
  font-weight: normal;
  font-style: normal;
}

.BrandonGrotesqueRegularItalic {
  font-family: "Brandon Grotesque";
  font-weight: normal;
  font-style: italic;
}

.BrandonGrotesqueThinItalic {
  font-family: "Brandon Grotesque";
  font-weight: 100;
  font-style: italic;
}

.BrandonGrotesqueMedium {
  font-family: "Brandon Grotesque";
  font-weight: 500;
  font-style: normal;
}

.BrandonGrotesqueThin {
  font-family: "Brandon Grotesque";
  font-weight: 100;
  font-style: normal;
}

.BrandonGrotesqueMediumItalic {
  font-family: "Brandon Grotesque";
  font-weight: 500;
  font-style: italic;
}
